@import url('https://fonts.googleapis.com/css2?family=Varela+Round&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Overpass:wght@300;400;600;700&display=swap');

*,
*:before,
*:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none; /* disable selection/Copy of UIWebView */
  -webkit-touch-callout: none; /* disable the IOS popup when long-press on a link */
}

input,
textarea {
  -webkit-user-select: text;
  -khtml-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

html,
body,
#root {
  height: 100%;
  width: 100%;
  background-color: #000000;
}
body {
  margin: 0;
  font-family: 'Varela Round', -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button:disabled {
  opacity: 0.4;
}

.grecaptcha-badge {
  z-index: 4;
}

#sending_circle {
  stroke-width: 10px;
  stroke-dasharray: 360;
  stroke-dashoffset: 360;
  animation: drawcircle 1s 0.5s forwards;
  transform: rotate(-90deg);
  transform-origin: 50%;
}

@keyframes drawcircle {
  to {
    stroke-dashoffset: 0;
  }
}
